const plans = [
  {
    id: "price_1R2ZyCLV0WATdPxE1kkTe6Tv",
    name: "Individual",
    description: "Ideal for individuals with basic MFA needs",
    price: "€9,99",
    seats: "1",
    features: [
      { text: "1 project", included: true },
      { text: "Secure Strapi admin panel with MFA", included: true },
      {
        text: "Full MFA access (SMS, Email, TOTP, Backup codes)",
        included: true,
      },
      { text: "Email verification 2FA", included: true },
      { text: "Early access to new features", included: false },
      { text: "Multi project license", included: false },
    ],
  },
  {
    id: "price_1R2a2pLV0WATdPxEbEMXpUw2",
    name: "Developer",
    seats: "3",
    description:
      "Best value for multi-project developers with early access to premium features",
    price: "€22,99",

    features: [
      { text: "Everything from the individual plan", included: true },
      { text: "3 projects", included: true },
      { text: "Priority updates", included: true },
      {
        text: "Early access to new features (SDK, React components)",
        included: true,
      },
      { text: "Multi-project support", included: true },
      { text: "Email support", included: true },
      { text: "Backup codes for device recovery", included: true },
    ],
  },
  {
    id: "price_1R2a4dLV0WATdPxEAJzyx5AI",
    name: "Agency",
    seats: "10",
    mostPopular: true,
    description: "Ideal for businesses who need MFA security at large scale",
    price: "€29,99",
    features: [
      { text: "Everything from the Developer plan", included: true },
      { text: "Supports up to 7-10 projects", included: true },
      { text: "Priority updates", included: true },
      {
        text: "Early access to new features (SDK, React components)",
        included: true,
      },
      { text: "Multi-project support", included: true },
      { text: "Email support", included: true },
      { text: "Backup codes for device recovery", included: true },
      { text: "Premium support (response time < 4h)", included: true },
    ],
  },
];

export { plans };
